* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  transition: 0.3s;
}

button {
  transition: 0.5s;
}

button:hover {
  transform: scale(1.05);
  transition: 0.2s;
}

.ui.progress .bar {
  background: #347ebf !important;
}

/* #container {
    border: 12px solid #B22222;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    }
.container-logs {
  white-space: pre-line;
  overflow-y: scroll;
}


    .marker {
        -webkit-transition: all 7s ease-in-out;
        -moz-transition: all 7s ease-in-out;
        -o-transition: all 7s ease-in-out;
        transition: all 7s ease-in-out;
      
        border-radius: 15px;
        border: 1px solid #FFF;
        display: inline-block;
        width: auto!important;
        height: auto!important;
        width: 30px!important;
        text-align: center;
        height: 30px!important;
        line-height: 30px;
        color: #FFF;
        font-weight: bold;
      }
      
      .bus {
        background-color: #333;
      }
      
      .rail90 {
        background-color: #D11241;
      }
      .rail100 {
        background-color: #0069AA;
      }
      .rail190 {
        background-color: #FFC423;
      }
      .rail200 {
        background-color: #008752;
      }
      .rail290 {
        background-color: #D25D13;
      }
 */

.ui.label {
  cursor: default !important;
  background-color: #3dadd9 !important;
  color: whitesmoke !important;
  width: 100%;
}

.ui.basic.green.button,
.ui.basic.green.buttons .button {
  box-shadow: 0 0 0 1px #3dadd9 inset !important;
  color: #3dadd9 !important;
}

.ui.card > .extra a:not(.ui),
.ui.cards > .card > .extra a:not(.ui) {
  color: rgba(0, 0, 0, 0.4);
  cursor: default;
}

.ui.card > .extra a:not(.ui),
.ui.cards > .card > .extra a:not(.ui):hover {
  color: rgba(0, 0, 0, 0.4) !important;
}

.ui.buttons:not(.basic):not(.inverted) > .button,
.ui.buttons > .ui.button:not(.basic):not(.inverted) {
  cursor: default !important;
}

.ui.card:last-child {
  margin-bottom: 0;
  box-shadow: 5px 2px 14px -8px rgb(0 0 0 / 81%);
  padding: 2em;
  border-radius: 8px;
}

.ui.card{
  background:rgba(255, 255, 255, 0.8) !important;
}

.MuiTypography-colorPrimary {
  color: #347ebf !important;
}

.MuiGrid-root .MuiGrid-container {
  height: 8vh;
}

.videoHome {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.MuiGrid-root .MuiGrid-item {
  z-index: 1;
}
/* 
.MuiListItem-button{
  box-shadow: 0px 2px 4px -1px #919191;
} */

.divBlur {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(../src/util/imgs/sch-home.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(3px);
}

.divBosqueBlur {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-image: url(../src/util/imgs/sch-bosque.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(3px);
}

.MuiButton-textPrimary {
  color: #347ebf !important;
}

.MuiButton-textPrimary:focus {
  color: #347ebf;
}

.MuiButton-containedPrimary {
  background-color: #347ebf !important;
}
.Mui-focusVisible {
  color: #347ebf !important;
}

.Mui-focused{

  color: #347ebf !important;
}


h1{
  font-size: 1.5rem !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400 !important;
    line-height: 1.334 !important;
    letter-spacing: 0em !important;
}

.ui.card>.content>.header, .ui.cards>.card>.content>.header {
  display: block;
  margin: '';
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  color: rgba(0,0,0,.85);
  font-size: 16px !important;
}

.ui.card>.content>.description, .ui.cards>.card>.content>.description {
  clear: both;
  color: rgba(0,0,0,.68);
  font-size: 14px !important;
}

.ui.card>.content>.header+.description, .ui.card>.content>.meta+.description, .ui.cards>.card>.content>.header+.description, .ui.cards>.card>.content>.meta+.description{
margin-top: 0.1em !important;
}

.ui.card {
 margin: 0px !important; 
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #347ebf !important;
}